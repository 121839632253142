#kanban {
    white-space: nowrap;
  }
  
  .list {
    border-radius: 8px;
    margin: 5px;
    background-color: rgba(192, 192, 192, 0.4);
    display: inline-block;
    vertical-align: top;
    white-space: normal;
  }
  
  .list-title {
    font-size: 16px;
    padding: 10px;
    padding-left: 30px;
    margin-bottom: -10px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .scrollable-list {
    height: 900px;
    width: 480px;
  }
  
  .sortable-cards {
    // min-height: 380px;
    height: 900px;
    width: 480px;
        
  display: flex;
  flex-wrap: wrap;    
  flex-direction: column;  
  }
  
  .card {
    position: relative;
    background-color: white;
    box-sizing: border-box;
    width: 200px;
    padding: 10px 20px;
    margin: 10px;
    cursor: pointer;
  }
  
  .card-subject {
    padding-bottom: 10px;
  }
  
  .card-assignee {
    opacity: 0.6;
  }
  
  .card-priority {
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: 5px;
    width: 5px;
    border-radius: 2px;
    background: #86c285;
  }
  
  .priority-1 {
    background: #adadad;
  }
  
  .priority-2 {
    background: #86c285;
  }
  
  .priority-3 {
    background: #edc578;
  }
  
  .priority-4 {
    background: #ef7d59;
  }
  
  .customTable {
    border-collapse: collapse;
    width: 100%;
  }

  .customTable td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
    width: 30%;
    height: 60px;
  }

  .customTable textarea {
    width: calc(100% - 16px); /* Adjust for padding */
    height: 75px;
    padding: 8px;
    resize: none;
    border: none;
    box-sizing: border-box;
    font-family: inherit; /* Use the same font as the rest of the content */
  }