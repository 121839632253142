  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    border: 1px solid black;
    // padding: 8px;
    text-align: left;
  }

  input{
    width: 100%;
    border: 0px;
  }